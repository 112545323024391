<template>
    <header :class=" isOpen ? 'active' : '' ">
        <div class="container">
            <button @click="mobileMenu" class="gamberger-btn btn btn-transparent btn-square btn-s30">
                 <i :class=" isOpen ? 'fas fa-times' : 'fas fa-bars' "></i>
            </button>
            <div class="logo" >
                <router-link to="/" data-tag="main_header_logo"><img src="@/assets/logo.svg" alt="" @click="mobileMenuClose"></router-link>
            </div>
            <div class="donate" @click="mobileMenuClose">
                <router-link to="/donate" class="btn btn-sm" exact-active-class="active" data-tag="main_header_donate">{{ $t('header.donate') }}</router-link>
            </div>
            <div class="menu">
                <nav :class="['navigation', { 'container': shouldAddContainerClass }]">
                    <router-link class="" to="/donate" exact-active-class="active" @click="mobileMenuClose" data-tag="main_header_donate">{{ $t('header.donate') }}</router-link>
                    <router-link class="home" to="/" exact-active-class="active" @click="mobileMenuClose" data-tag="main_header_home">{{ $t('header.home') }}</router-link>
                    <router-link class="" to="/about" exact-active-class="active" @click="mobileMenuClose" data-tag="main_header_about">{{ $t('header.about') }}</router-link>
                    <!-- <router-link class="" to="/stories" exact-active-class="active" @click="mobileMenuClose" data-tag="main_header_stories">{{ $t('header.stories') }}</router-link> -->
                    <router-link class="" to="/cooperation" exact-active-class="active" @click="mobileMenuClose" data-tag="main_header_cooperation">{{ $t('header.cooperation') }}</router-link>
                </nav>
                <div :class="['language', { 'container': shouldAddContainerClass }]">
                    <p>Language</p>
                    <div>
                        <button @click="changeLocale('en')" class="btn btn-transparent btn-p0" :class="{ 'active' : this.$i18n.locale === 'en' }" data-tag="main_header_change_lang_en"> 
                            <p class="long">English</p>
                            <p class="short">EN</p>
                        </button>
                        <button @click="changeLocale('uk')" class="btn btn-transparent btn-p0" :class="{ 'active' : this.$i18n.locale === 'uk' }" data-tag="main_header_change_lang_ua"> 
                            <p class="long">Українська</p>
                            <p class="short">UK</p>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
export default {
    name: 'SiteHeader',
    data() {
        return {
            shouldAddContainerClass: window.innerWidth < 992
        };
    },
    props: {
        isOpen: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        changeLocale(locale) {
            this.$i18n.locale = locale;
        },
        mobileMenu(){
            this.$emit("mobileMenu", this.isOpen )
        },
        mobileMenuClose(){
            this.$emit("closeMobileMenu")
        },
        handleResize() {
            this.shouldAddContainerClass = window.innerWidth < 992;
        }
    },
    created() {
        window.addEventListener('resize', this.handleResize);
    },
    unmouted() {
        window.removeEventListener('resize', this.handleResize);
    },
}
</script>

<style scoped lang="sass">
header
    position: sticky
    top: 0
    padding: 10px 0
    background: var(--theme-white)
    color: var(--theme-white)
    z-index: 1000
    & .container
        display: flex
        justify-content: flex-start
        align-items: center
    a , button 
        color: var(--Black)
        &.active 
            color: var(--GreenBright)

.logo
    & img
        height: 56px
        pointer-events: none

.gamberger-btn
    display: none
    margin-right: 1rem
    
.btn.gamberger-btn
    color: var(--Black)
    &:hover 
        color: var(--Black)

button
    & .long
        display: none

.donate
    margin-left: auto
    & a
        border: 1px solid var(--GreenBright)
        &:hover
            border: 1px solid var(--GreenBright)
            color: var(--theme-white)
            background: var(--GreenBright)

.menu 
    display: flex
    justify-content: flex-start
    align-items: center
   
    & nav
        & a 
            margin-left: 1rem
            &:first-child
                display: none
    .language
        margin-left: 1rem
        & > p 
            display: none
            color: var(--theme-gray)
            line-height: 1
        & button 
            margin-left: 1rem
            color: var(--Black)
            &.active 
                color: var(--GreenBright)
            & > p
                pointer-events: none

@media screen and (max-width: 991px)
    .logo
        & img
           height: 40px

    .gamberger-btn
        display: flex
        justify-content: center
        align-items: center

    button
        & .long
            display: block
        & .short
            display: none

    .donate
        font-size: calc(1rem - 4px)

    .menu 
        font-size: calc(1rem + 4px)
        position: absolute
        top: 100%
        left: 0
        width: 100%
        left: 0
        flex-grow: 1
        background: var(--theme-white)
        flex-direction: column
        justify-content: center
        overflow: hidden
        height: 0
        max-height: 0
        transition: 0.3s all
        & .home
            order: 1
        & a
            order: 2
        & .container
            --bs-gutter-x: 1.5rem
            padding-left: calc(var(--bs-gutter-x) * 0.5 + 30px + 1rem)
        & nav
            flex-direction: column
            align-items: flex-start
            & a 
                margin-left: 0
                padding-top: 2vh
                padding-bottom: 2vh
                width: 100%
                display: block
                &:first-child
                    display: block
        .language
            flex-direction: column
            align-items: flex-start
            margin-left: auto
            margin-top: 8vh
            border-left: 0
            & > p 
                display: block
                padding-top: 2vh
                padding-bottom: 2vh
            & button 
                margin-left: 1rem
                &:first-child
                    margin-left: 0

    header
        &.active
            .menu 
                height: calc(100vh - 100%)
                height: calc((var(--vh, 1vh) * 100) - 100%)
                max-height: calc(100vh - 100%)
                max-height: calc((var(--vh, 1vh) * 100) - 100%)
                overflow: auto
                padding: 30px 0
</style>
