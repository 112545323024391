<template>
    <section id="cookies-banner">
        <div class="container">
            <div class="row">
                <p>{{$t('cookies_banner.content')}} <router-link to="/privacy-policy">{{$t('cookies_banner.link')}}</router-link></p>
                <div class="btns">
                    <button @click="hideBanner('decline')" class="btn btn-empty">{{$t('cookies_banner.decline')}}</button>
                    <button @click="hideBanner('accept')" class="btn btn-primary">{{$t('cookies_banner.accept')}}</button>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default{
    name: "CookiesBanner",
    data() {
        this.createData();
        return {
        };
    },
    methods: {
        createData() {
            if (this.$i18n.locale === "uk") {
                this.label = this.$i18n.messages.uk.cookies_banner
            } else if (this.$i18n.locale === "en") {
                this.label = this.$i18n.messages.en.cookies_banner
            }
        },
        hideBanner(action) {
            localStorage.setItem("hide" , true)
            localStorage.setItem("cookies-result" , action)
            document.getElementById("cookies-banner").classList.remove("active");
        },
        checkLocalStorage() {
            if (!localStorage.getItem("hide")) {
                document.getElementById("cookies-banner").classList.add("active");
            } else {
                document.getElementById("cookies-banner").classList.remove("active");
            }
        }
    },
    mounted() {
        this.createData();
        this.checkLocalStorage();
    },
    watch: {
        '$i18n.locale': function(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.createData()
            }
        }
    },
}
</script>

<style lang="sass" scoped>
#cookies-banner
    background: var(--White)
    border-top: 1px solid var(--Gray6)
    position: fixed
    left: 0
    bottom: -100vh
    width: 100%
    z-index: 1000
    padding: 30px 0
    text-align: center
    transition: 0.3s
    & p 
        padding-bottom: 20px
    & .btns
        button
            margin-right: 15px
    &.active 
        bottom: 0
        

@media screen and (max-width: 991px)
    #cookies-banner
        & p 
           font-size: calc(1rem - 2px)
</style>
