<template>
    <div class="site">
        <SiteHeader ref="siteHeader" :isOpen="isOpen" @mobileMenu="mobileMenu" @closeMobileMenu="closeMobileMenu" @click.stop/>
        <main>
            <keep-alive>
                <router-view :siteHeaderHeight="siteHeaderHeight" :key="$route.fullPath"/>
            </keep-alive>
        </main>
        <SiteFooter />
        <CookiesBanner />
    </div>
</template>

<script>
import SiteHeader from '@/components/SiteHeader.vue'
import SiteFooter from '@/components/SiteFooter.vue'
import CookiesBanner from '@/components/CookiesBanner.vue'

import 'bootstrap/dist/css/bootstrap.css';

export default {
    name: 'HomeView',
    data() {
        return {
            isOpen: false,
            siteHeaderHeight: 0,
        }
    },
    components: {
        SiteHeader,
        SiteFooter,
        CookiesBanner,
    },
    methods: {
        mobileMenu() {
            this.isOpen = !this.isOpen
        },
        closeMobileMenu() {
            this.isOpen = false
        },
        updateVh() {
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        },
        updateSiteHeaderHeight() {
            this.$nextTick(() => {
                this.siteHeaderHeight = this.$refs.siteHeader.$el.clientHeight;
            });
        },
    },
    mounted() {
        this.updateVh();
        this.updateSiteHeaderHeight();
        
        window.addEventListener('resize', this.updateVh); 
        window.addEventListener('resize', this.updateSiteHeaderHeight);
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.updateVh);
        window.removeEventListener('resize', this.updateSiteHeaderHeight);
    },
}
</script>

<style lang="sass">
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;500;600;700;800&display=swap')
@import url('https://fonts.googleapis.com/css2?family=Prata&display=swap')
:root 
    --theme-primary-light: #4874e4
    --theme-primary: #002ea4
    --theme-primary-dark: #03257d
    --theme-secondary: #ea8835
    --theme-white: #ffffff
    --theme-semiwhite: #f0f0f0
    --theme-gray-light: #b5b9c9
    --theme-gray: #818595

    --Black: #151515
    --Mint: #61B59C
    --GreenBright: #32C398
    --Green3: #6FCF97
    --Peach: #E09A6D
    --Buttons: #E79059
    --LightYellow: #FFF3BB
    --White: #FFFFFF
    --Black: #151515
    --LightGrey: #F2F2F2
    --DarkGrey: #79797A
    --Gray1: #333333
    --Gray2: #4F4F4F
    --Gray3: #828282
    --Gray4: #a1a1a1
    --Gray5: #E0E0E0
    --Gray6: #F2F2F2

html
    font-size: 18px

body
    font-family: "Manrope", sans-serif
    font-size: inherit
    font-weight: 400
    position: relative
    line-height: 1,365
    color: var(--Gray1)


        
    & * 
        padding: 0
        margin: 0
        box-sizing: border-box !important
        outline: none

a , button
    text-decoration: none
    transition: 0.3s all
    line-height: 1 !important
    font-size: inherit !important
    & * 
        line-height: 1

.row 
    margin-left: 0
    margin-right: 0
    & > *
        padding-left: 0
        padding-right: 0

.btn
    font-weight: 500
    border-radius: 0.375rem
    box-shadow: 0 0 0 0 transparent !important
    &.btn-primary
        color: var(--White)
        background-color: var(--Mint)
        border-color: var(--Mint)
        &:active
            color:var(--White)
            background-color: var(--Mint)
            border-color: var(--Mint)
    &.btn-white
        color: var(--Black)
        background-color: var(--theme-white)
        border-color: var(--theme-white)
        &:active
            color: var(--Black)
            background-color: var(--theme-white)
            border-color: var(--theme-white)
    &.btn-secondary
        color: var(--theme-white)
        background-color: var(--theme-secondary)
        border-color: var(--theme-secondary)
        &:active
            color: var(--theme-white)
            background-color: var(--theme-secondary)
            border-color: var(--theme-secondary)

    &.btn-transparent
        color: var(--theme-white)
        background-color: transparent
        border-color: transparent
        &:active
            color: var(--theme-white)
            background-color: transparent
            border-color: transparent

    &.btn-white-empty
        color: var(--theme-white)
        background-color: transparent
        border-color: var(--theme-white)
        &:active , &:hover
            color: var(--Black)
            background-color: var(--theme-white)
            border-color: var(--theme-white)
    
    &.btn-black
        color: var(--White)
        background-color: var(--Black)
        border-color: var(--Black)
        box-shadow: 0 0 0 0 rgba(21, 21, 21, 0.25) !important
        &:active , &:hover
            color: var(--White)
            background-color: var(--Black)
            border-color: var(--Black)
            box-shadow: 0 0 0 10px rgba(21, 21, 21, 0.25) !important

    &.btn-p0
        padding: 0px
    &.btn-p5
        padding: 5px
    &.btn-p10
        padding: 10px

    &.btn-square
        &.btn-s30 
            width: 30px
            max-width: 30px
            min-width: 30px
            height: 30px
            max-height: 30px
            min-height: 30px

    &.btn-close 
        background: initial
        opacity: 1
        color: var(--theme-white)
        background-color: rgba(199, 199, 199, 0.7)
        border-color: var(--theme-white)
        &:active
            color: var(--theme-white)
            background-color: rgba(199, 199, 199, 0.7)
            border-color: var(--theme-white)

.btn
    &.btn-xlg
        min-width: 253px
        padding: 5px 60px
        min-height: 78px
        line-height: 1
        font-size: calc(1.3rem + 0.6vw) !important
        display: inline-flex
        justify-content: center
        align-items: center
    &.btn-lg
        min-width: 253px
        padding: 5px 30px
        min-height: 58px
        line-height: 1
        font-size: calc(1.2rem + 0.1vw) !important
        display: inline-flex
        justify-content: center
        align-items: center
        & i 
            position: relative
            font-size: calc(1em - 4px)
            margin-left: 15px
            top: 0px
            display: inline-flex
        & * 
            line-height: 1
    &.btn-sm
        padding: 5px 15px
        min-height: 42px
        line-height: 1
        font-size: 1rem !important
        display: inline-flex
        justify-content: center
        align-items: center

.inp
    &.inp-lg
        padding: 5px 15px
        min-height: 58px
        line-height: 1
        font-size: calc(1.2rem + 0.1vw) !important
        display: inline-flex
        justify-content: center
        align-items: center
        &::placeholder
            font-size: calc(1.2rem + 0.1vw) !important
        & i 
            position: relative
            font-size: calc(1em - 4px)
            margin-left: 15px
            top: 0px
            display: inline-flex
        & * 
            line-height: 1
    &.inp-sm
        padding: 5px 15px
        min-height: 52px
        line-height: 1
        font-size: 1rem !important
        display: inline-flex
        justify-content: center
        align-items: center
        &::placeholder
            font-size: 1rem !important
.txta
    &.txta-lg
        padding: 10px 15px
        min-height: 58px
        line-height: 1.5
        font-size: calc(1.2rem + 0.1vw) !important
        display: inline-flex
        justify-content: center
        align-items: center
        &::placeholder
            font-size: calc(1.2rem + 0.1vw) !important
            line-height: 1.5
    &.txta-sm
        padding: 10px 15px
        min-height: 52px
        line-height: 1.5
        font-size: 1rem !important
        display: inline-flex
        justify-content: center
        align-items: center
        &::placeholder
            font-size: 1rem !important
            line-height: 1.5
            
.sel 
    &.sel-lg
        padding: 5px 15px
        min-height: 58px
        line-height: 1
        font-size: calc(1.2rem + 0.1vw) !important
    &.sel-sm
        padding: 5px 15px
        min-height: 52px
        line-height: 1
        font-size: 1rem !important

@media screen and (max-width: 991px)
    .btn
        &.btn-xlg
            padding: 5px 50px
            min-height: 68px
        &.btn-lg
            min-height: 48px
            font-size: 1.15rem !important
        &.btn-sm
            min-height: 38px
            font-size: calc(1rem - 2px) !important
    .inp
        &.inp-lg
            min-height: 48px
            font-size: 1.15rem !important
            &::placeholder
                font-size: 1.15rem !important
        &.inp-sm
            min-height: 48px
            font-size: calc(1rem - 2px) !important
            &::placeholder
                font-size: calc(1rem - 2px) !important

    .txta
        &.txta-lg
            min-height: 48px
            font-size: 1.15rem !important
            &::placeholder
                font-size: 1.15rem !important
        &.txta-sm
            min-height: 48px
            font-size: calc(1rem - 2px) !important
            &::placeholder
                font-size: calc(1rem - 2px) !important

    .sel 
        &.sel-lg
            min-height: 48px
            font-size: 1.15rem !important
        &.sel-sm
            min-height: 48px
            font-size: calc(1rem - 2px) !important

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button
  -webkit-appearance: none
  margin: 0
input[type=number]
  -moz-appearance: textfield

.primary
    background: var(--theme-primary)
    display: inline
    padding: 3px 0
    padding-inline-end: 10px
    padding-inline-start: 10px
    -webkit-box-decoration-break: clone
    box-decoration-break: clone
    color: var(--theme-white)
.primary-text
    color: var(--theme-primary)
.secondary
    background: var(--theme-secondary)
    display: inline
    padding: 3px 0
    padding-inline-end: 10px
    padding-inline-start: 10px
    -webkit-box-decoration-break: clone
    box-decoration-break: clone
    color: var(--theme-white)
.secondary-text
    color: var(--theme-secondary)



@media (min-width: 576px)
    .container 
        max-width: 100%

@media (min-width: 768px)
    .container 
        max-width: calc(100% - 40px)

@media (min-width: 970px)
    .container 
        max-width: calc(100% - 80px)

@media (min-width: 1200px)
    .container 
        max-width: calc(100% - 120px)
@media (min-width: 1600px)
    .container 
        max-width: calc(100% - 400px)

.card 
    border: 0 solid transparent
    background: transparent
    border-radius: 0
    color: inherit
    flex-direction: row
    align-content: flex-start
    flex-wrap: wrap

// slider pagination
.swiper-pagination 
    padding: 0 30px
    display: flex
    justify-content: center
    align-items: center
.swiper-pagination-bullets
    display: flex 
    & .swiper-pagination-bullet
        width: 25px
        height: 12px
        background: rgba(133, 133, 134, 0.7)
        display: block
        border-radius: 1000px
        transition: 0.3s
        &:not(:last-child) 
            margin-right: 8px
        &.swiper-pagination-bullet-active 
            width: 56px
            background: var(--theme-white)

@media screen and (max-width: 991px)
    .swiper-pagination 
        padding: 30px 30px

.swiper-pagination
    &.swiper-pagination--primary-theme
        &.swiper-pagination-bullets
            & .swiper-pagination-bullet
                background: var(--Gray5)
                &.swiper-pagination-bullet-active 
                        background: var(--Mint)
    &.swiper-pagination--secondary-theme
        &.swiper-pagination-bullets
            & .swiper-pagination-bullet
                background: rgba(199, 199, 199, 0.7)
                &.swiper-pagination-bullet-active 
                        background: var(--theme-secondary)

// slider navigation
.swiper-button-prev
    left: -20px
    & img
        transform: rotate(180deg)
.swiper-button-next
    right: -20px
.swiper-button-prev,
.swiper-button-next
    transition: 0.3s
    position: absolute
    top: 50%
    transform: translateY(-50%)
    z-index: 2
    & img
        width: 60px

.site
    display: flex
    flex-direction: column
    min-height: calc(100 * var(--vh))
footer
    margin-top: auto

.slide-fade-enter-active
  transition: all 0.3s ease-out

.slide-fade-leave-active
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1)

.slide-fade-enter-from, .slide-fade-leave-to 
  transform: translateY(20px)
  opacity: 0

form input,
.form input,
form input::placeholder,
.form input::placeholder,
form textarea,
.form textarea,
form textarea::placeholder,
.form textarea::placeholder,
form select
    font-size: calc(1rem - 3px) !important
form label , .form label
    padding-left: 0.375rem
    padding-right: 0.375rem
    font-size: calc(1rem - 3px)
form textarea , .form textarea
    resize: none
    height: 120px
form select , .form select
    line-height: 1.5 !important

h1 , h2 
    font-family: "Prata", sans-serif
    font-weight: 400
    line-height: 1.538


.container, .container-fluid, .container-xxl, .container-xl, .container-lg, .container-md, .container-sm
    padding-right: 20px
    padding-left: 20px
</style>