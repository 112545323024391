<template>
    <footer>
        <div class="container">
            <div class="row">
                <div class="content">
                    <div class="logo">
                        <router-link to="/"><img src="@/assets/logo.svg" alt=""></router-link>
                    </div>
                    <p>© <span class="year">{{year}}</span> {{ $t('footer.company') }}</p>
                </div>
                <div class="social">
                    <ul>
                        <li>
                            <a :href="$t('footer.social.telegram.link')" target="_blank">
                                <i class="fa-solid fa-paper-plane"></i>
                            </a>
                        </li>
                        <li>
                            <a :href="$t('footer.social.mail.link_before') + '@' +  $t('footer.social.mail.link_after')" target="_blank">
                                <i class="fa-solid fa-envelope"></i>
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="rules">
                    <router-link class="" to="/privacy-policy" exact-active-class="active">{{ $t('footer.rules.privacy_policy') }}</router-link>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
  name: 'SiteFooter',
  data() {
    return {
        year: new Date().getFullYear()
    }
  }
}
</script>

<style scoped lang="sass">
footer
    padding: 30px 0
    background: var(--Mint)
    color: var(--Black)
    text-align: center
    & .row
        display: flex 
        justify-content: space-between 
        align-items: center
        flex-direction: column
        & > *
            width: auto 
    & .content
        & .logo
            margin-bottom: 15px
            & img
                height: 56px
        & p
            font-size: calc(1rem - 4px)
    & .social
        margin-top: 15px
        & ul
            display: flex
            justify-content: flex-end
            align-items: center
            & li 
                list-style-type: none
                &:not(:last-child)
                    margin-right: 15px
                & a 
                    color: var(--Black)
                    &:hover
                        color: var(--White)
                    & i 
                        font-size: 2rem
    & .rules
        font-size: calc(1rem - 4px)
        margin-top: 15px
        a 
            color: var(--Black)

@media screen and (max-width: 991px)
    footer
        & .content
            & .logo
                & img
                    height: 40px

</style>
